/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const driverPageState = reactive({
	showGetDriverTableDataComponent: true,
	showDriversTableComponent: false,
	showPopup: false,
	driversRows: '',
	driversColumns: [
		{
			label: 'ID',
			field: 'driver_id',
		},
		{
			label: 'Name',
			field: 'driver_name',
		},
		{
			label: 'Person Nummer',
			field: 'driver_person_nummer',
		},
		{
			label: 'Edit',
			field: 'edit',
			width: '50px',
			sortable: false,
		},
	],
})

export default function useDriverPageState() {
	return {
		...toRefs(driverPageState),
	}
}
