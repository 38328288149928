<template>
	<div>
		<V3Table :columns="driversColumns" :rows="driversRows">
			<div slot="header">
				<div>Active Drivers</div>
			</div>
			<div slot="footer">
				<div>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(182, 90, 92); color: white"
						@click="onAddDriver"
						:disabled="disableButtons"
					>
						Add New Driver
					</b-button>
				</div>
			</div>
		</V3Table>
		<div v-if="showPopup">
			<V3Popup :closePopup="onClose">
				<div slot="header">
					Add New Driver - Fill the form to enter new driver details
				</div>
				<div slot="body">Add New Driver</div>
				<div slot="footer">Add New Driver</div>
			</V3Popup>
		</div>
	</div>
</template>

<script>
import V3Table from '@/_srcv2/components/v3/v3-table/V3Table.vue'
import V3Popup from '@/_srcv2/components/v3/v3-popup/V3Popup.vue'
import useDriverPageState from '@/_srcv2/pages/deliveries-route-lists/driver/store/useDriverPageState.js'
import { ref } from '@vue/composition-api'

export default {
	name: 'DriversTableComponent',
	components: { V3Table, V3Popup },
	setup() {
		const { driversRows, driversColumns, showPopup } = useDriverPageState()
		const disableButtons = ref(false)
		const onAddDriver = () => {
			console.log('onAddDriver')
			disableButtons.value = true
			showPopup.value = true
			setTimeout(() => {
				disableButtons.value = false
			}, 1000)
		}
		const onClose = () => {
			showPopup.value = false
		}
		return {
			driversRows,
			driversColumns,
			onAddDriver,
			disableButtons,
			showPopup,
			onClose,
		}
	},
}
</script>

<style lang="scss" scoped></style>
