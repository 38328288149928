<template>
	<LayoutDefaultNew>
		<SubNavbar page="Delivery" />
		<div>
			<div v-if="showGetDriverTableDataComponent">
				<GetDriverTableDataComponent />
			</div>
			<div v-if="showDriversTableComponent">
				<DriversTableComponent />
			</div>
		</div>
	</LayoutDefaultNew>
</template>

<script>
import GetDriverTableDataComponent from '@/_srcv2/pages/deliveries-route-lists/driver/components/GetDriverTableDataComponent.vue'
import DriversTableComponent from '@/_srcv2/pages/deliveries-route-lists/driver/components/DriversTableComponent.vue'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import useDriverPageState from '@/_srcv2/pages/deliveries-route-lists/driver/store/useDriverPageState.js'
export default {
	name: 'AddEditDriver',
	components: {
		SubNavbar,
		GetDriverTableDataComponent,
		DriversTableComponent,
	},
	setup() {
		const { showGetDriverTableDataComponent, showDriversTableComponent } =
			useDriverPageState()
		return {
			showGetDriverTableDataComponent,
			showDriversTableComponent,
		}
	},
}
</script>

<style lang="scss" scoped></style>
