<template>
	<div>
		<div
			style="
				background-color: rgb(76, 92, 122);
				color: white;
				font-size: larger;
				font-weight: bold;
				padding: 20px;
			"
		>
			<slot name="header" />
		</div>
		<vue-good-table
			:columns="$props.columns"
			:rows="$props.rows"
			@on-row-dblclick="$props.onRowDoubleClick"
			@on-cell-click="$props.onCellClick"
			styleClass="vgt-table striped bordered"
			:theme="$props.theme"
			:max-height="$props.maxHeight"
			:fixed-header="true"
			:search-options="{ enabled: $props.searchedOptions }"
			:sort-options="$props.sortOptions"
			:pagination-options="$props.paginationOptions"
		>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field === 'select'">
					<b-button class="is-small is-success">Select</b-button>
				</span>
				<span v-if="props.column.field === 'preview'">
					<b-button class="is-small is-info">Preview</b-button>
				</span>
				<span v-if="props.column.field === 'edit'">
					<b-button class="is-small is-danger">Edit</b-button>
				</span>
				<span v-if="props.column.field === 'delete'">
					<b-button class="is-small is-danger">Delete</b-button>
				</span>
				<span v-if="props.column.field === 'remove'">
					<b-button class="is-small is-danger">Remove</b-button>
				</span>
				<span v-if="props.column.field === 'lock'">
					<b-button class="is-small is-success">Lock</b-button>
				</span>
				<span v-if="props.column.field === 'unlock'">
					<b-button class="is-small is-warning">Unlock</b-button>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
		<div
			style="
				background-color: rgb(76, 92, 122);
				color: white;
				font-size: larger;
				font-weight: bold;
				padding-left: 10px;
				padding-bottom: 30px;
			"
		>
			<slot name="footer" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'V3Table',
	props: {
		columns: {
			type: Array,
			default: () => [],
		},
		rows: {
			type: Array,
			default: () => [],
		},
		onCellClick: {
			type: Function,
			default: () => {},
		},
		onRowDoubleClick: {
			type: Function,
			default: () => {},
		},
		theme: {
			default: 'black-rhino',
			type: String,
		},
		maxHeight: {
			default: '700px',
			type: String,
		},
		searchedOptions: {
			default: true,
			type: Boolean,
		},
		sortOptions: {
			type: Object,
		},
		paginationOptions: {
			default: () => {
				return {
					enabled: true,
					mode: 'records',
					perPage: [5, 10, 15, 20],
					position: 'bottom',
					perPageDropdown: 10,
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}
			},
			type: Object,
		},
		perPageDropdown: {
			default: () => [5, 10, 15, 20],
			type: Array,
		},
		perPage: {
			default: () => 10,
			type: Number,
		},
	},
	setup(props) {
		return {
			props,
		}
	},
}
</script>

<style lang="scss" scoped></style>
