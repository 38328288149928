<template>
	<div v-if="loading">Loading...</div>
</template>

<script>
import GetDriversQuery from '@/_srcv2/pages/deliveries-route-lists/driver/graphql/GetDriversQuery.graphql'
import { useQuery } from '@vue/apollo-composable'

import useDriverPageState from '@/_srcv2/pages/deliveries-route-lists/driver/store/useDriverPageState.js'
export default {
	name: 'GetDriverTableDataComponent',
	setup() {
		const {
			driversRows,
			showGetDriverTableDataComponent,
			showDriversTableComponent,
		} = useDriverPageState()
		const { onResult, onError, loading } = useQuery(GetDriversQuery)
		onResult((result) => {
			console.log(result.data.drivers)
			driversRows.value = result.data.drivers
			console.log('driversRows.value', driversRows.value)
			showGetDriverTableDataComponent.value = false
			showDriversTableComponent.value = true
		})
		onError((error) => {
			console.log(error)
		})
		return { loading }
	},
}
</script>

<style lang="scss" scoped></style>
